import React, { useState } from 'react';
import { ImageList, ImageListItem, ImageListItemBar, Dialog, DialogContent } from '@mui/material';


const photos = [
  { img: 'https://i.imgur.com/qoqh9tF.jpeg', title: 'Comes as a flat cardstock' },
  { img: 'https://i.imgur.com/fhRjzKC.jpeg', title: 'As you can see fits a toploader' },
  { img: 'https://i.imgur.com/o9d8xiQ.jpeg', title: 'Folding into a card holder shape' },
  { img: 'https://i.imgur.com/1VDTUa2.jpeg', title: 'Slide the sides in adjusting for toploader length' },
  { img: 'https://i.imgur.com/fcGXSBK.jpeg', title: 'Can fit a few toploaders or a toploader with a few cards in a team bag' },
  { img: 'https://i.imgur.com/v4GO6F0.jpeg', title: 'Sliding all the product into the card holder' },
  { img: 'https://i.imgur.com/2rrC6nW.jpeg', title: 'Nice and snug, could even slip in some bubble wrap' },
  { img: 'https://i.imgur.com/WNZHceD.jpeg', title: 'Close the holder shut' },
  { img: 'https://i.imgur.com/kqCUXuG.jpeg', title: '1.4oz for 8 loose cards and 2 cards in a toploader' },

  

];

const PhotoSlideshow = () => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
  
    const handleClickOpen = (img) => {
      setSelectedImage(img);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setSelectedImage(null);
    };
  
    return (
        <>
          <ImageList sx={{ width: '100%', height: 750 }} cols={3}>
            {photos.map((item) => (
              <ImageListItem key={item.img} onClick={() => handleClickOpen(item.img)}>
                <img
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar title={item.title} />
              </ImageListItem>
            ))}
          </ImageList>
    
          <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogContent>
              {selectedImage && (
                <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
              )}
            </DialogContent>
          </Dialog>
        </>
      );
    };
    
    export default PhotoSlideshow;