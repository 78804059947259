import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import SetPage from './components/SetDetail';
import CardPage from './components/CardSinglePage';
import { CssBaseline } from '@mui/material';
import Header from './components/Header';
import Sleevee from './components/Sleevee'

function App() {
  return (
      <div className="App">
        <CssBaseline />
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/:setName" element={<SetPage />} />
          <Route path="/card/:setName/:cardName" element={<CardPage />} />
          <Route path="/sleevee" element={<Sleevee />} />
        </Routes>
      </div>
  );
}

export default App;
