import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';

const useFetchEVData = () => {
  const [evData, setEvData] = useState(null);
  const [error, setError] = useState(null); // Add this line

  useEffect(() => {
    axios.get('/api/ev')
      .then(response => {
        console.log('EV Data fetched:', response.data);
        setEvData(response.data);
      })
      .catch(error => {
        console.error('Error fetching the EV data:', error);
      });
  }, []);

  return { evData };
};

export default useFetchEVData;
