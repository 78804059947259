import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQSection() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">How to use:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2}}>
            EV of each pack means the expected value. Meaning if you spend $5 on a pack and its EV is $4, you can expect close to $4 back from the pack ON AVERAGE. 
            This isn't a guarantee every time, but an educated guess over a number of pack openings. Some packs could return more, some less.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2}}>
            It's a way of calculating the best bets to take. This is most helpful when you find sales online or in person and want to check if it's worth buying a few boxes
            to pull the cards because let's face it opening cards is too much fun. If you find packs for $2 and the EV is $2.8, then over a long enough run of packs opened, 
            you could potentially, again not a gurantee, make money opening the packs. It's rare and there's still random variability, 
            but it's nice to try and open packs where it doesn't feel like a total loss.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2}}>
            This is also helpful in calculating if those beautiful ETBs you have stored away are worth ripping open just for a peek. For example it's absolutely not worth me 
            opening my Fusion Strike Pokemon Center ETBs as the EV is just so low. Just buy the damn Gengar!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FAQSection;
