import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import useFetchEVData from '../hooks/useFetchEVData';
import useFetchCardsData from '../hooks/useFetchCardsData';
import Set from './Set';
import config from '../config';
import { Link } from 'react-router-dom';
import FAQSection from './FAQSection';


function Dashboard() {
  const { evData } = useFetchEVData();
  const { cardData } = useFetchCardsData();


  if (!evData) {
    return <div>Loading...</div>;
  }

  console.log("Dashboard EV Data fetched:", evData);
  console.log("Dashboard Card Data:", cardData);

  // Sort sets by total EV in descending order
  // Adjust the sorting logic to use the `set` field
  const sortedSetNames = evData.map(item => item.set).sort((a, b) => {
    const setA = evData.find(data => data.set === a);
    const setB = evData.find(data => data.set === b);
    return setB.totalEV - setA.totalEV;
  });
  console.log("Sorted Set Names: ", sortedSetNames)

  return (
    <Container>

      <div>
        <Typography variant="h2" gutterBottom>
          EV Calculator Results*
        </Typography>
        <Typography variant="p" gutterBottom>
        * these are not guarantees, these are only rough estimates based on approximate pull rates for what sets ev could be close to*
        </Typography>
      </div>
      
      <div>
        <FAQSection />
      </div>



      <Grid container spacing={2}>
        {sortedSetNames.map(setName => {
          const setConfig = config.SETS[setName];
          console.log("setConfig: ", setConfig)
          if (!setConfig) {
            console.error(`Set configuration not found for ${setName}`);
            return null;
          }

          const setEVData = evData.find(e => e.set === setName);

          if (!setEVData) {
            console.error(`EV data not found for ${setName}`);
            return null;
          }

          const chaseSingles = Object.values(setEVData)
            .filter(value => Array.isArray(value))
            .flat();

          const totalEV = setEVData.totalEV;


          console.log(`Card data being sent to Set component for ${setConfig.label}:`, cardData[setName]);

          return (
            <Grid item xs={12} sm={6} md={6} key={setName}>
              <Link to={`/${setName.toLowerCase().split('&')[0].trim().replace(/ /g, '_')}`}>
                <Card sx={{ height: 1150 }}>
                  <CardContent>
                    <Set 
                      key={setName} 
                      setName={setConfig.label} 
                      chaseSingles={chaseSingles} 
                      cardData={cardData[setName] || []} 
                      totalEV={totalEV} 
                    />
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Dashboard;
