import React from 'react';
import { Card, CardContent, Typography, Grid, CardMedia,  CircularProgress } from '@mui/material';
import config from '../config';
import { Link } from 'react-router-dom';
import CardDetail from './CardDetail';
import ImageWithLoading from './ImageWithLoading'


function Set({ setName, chaseSingles, cardData, totalEV }) {
  const getProductID = (cardName, cardNumber) => {

    // Ensure cardData is an array before proceeding
    if (!Array.isArray(cardData)) {
      console.error(`Expected cardData to be an array, but got:`, typeof cardData);
      return null;
    }

    // Find the card with matching name and number
    const card = cardData.find(c => c.cardName === cardName && c.number === cardNumber);

    return card ? card.productID : null;
  };

  const getSlug = (cardName) => {
    const slug = cardName.toLowerCase()
          .replace(/ /g, '-') // replace spaces with hyphens
          .replace(/(\d+)\/(\d+)/, '$1-$2') // replace the "/" with a hyphen
          .replace(/[^a-z0-9-]/g, '') // remove non-alphanumeric characters
          .replace(/-+/g, '-'); // replace multiple hyphens with a single hyphen

    return slug
  }

  

  // Display up to 6 cards?
  const displayedCards = chaseSingles.slice(0, 6);

  return (
    <div>
      
      <div key={setName}>
        <Typography variant="h4" gutterBottom>{setName}</Typography>
        <Typography variant="h6" gutterBottom>EV of each pack: ${totalEV}</Typography>
      </div>
      
      <Grid container spacing={3}>
        {displayedCards.map(card => (
          <Grid item xs={12} sm={6} md={4} key={card.cardName}>
            <Link to={`card/${setName.toLowerCase().replace(/ /g, '_')}/${getSlug(card.cardName)}`}>
              {/* Something to solve later to call card detail but setName returns like Silver Tempest & Silver Tempest Trainer Gallery */}
              {/* <CardDetail setName={setName} cardName={card.cardName} /> */}
              <Card >
              <ImageWithLoading src={`${config.IMAGE_BASE_URL}${getProductID(card.cardName, card.cardNumber)}.jpg`} alt={card.cardName} />

                {/* <CardMedia
                  component="img"
                  image={`${config.IMAGE_BASE_URL}${getProductID(card.cardName, card.cardNumber)}.jpg`}
                  alt={card.cardName}
                  style={{ objectFit: 'contain' }}
                /> */}
                <CardContent>
                  <Typography variant="h6">{card.cardName}</Typography>
                  <Typography>Price: ${card.marketPrice}</Typography>
                  <Typography>Pull Rate: {card.pullRate}%</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Set;
