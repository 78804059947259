import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import PhotoSlideshow from './PhotoSlideshow';

function Header() {
  return (
    <Container>
    <div>
        <Typography variant="h2" style={{ flexGrow: 1 }}>
          Sleevees
        </Typography>

        <div class="klaviyo-form-UfXwRi"></div>

        <Typography variant="h5" style={{ flexGrow: 1 }} sx={{paddingTop: 2, paddingBottom: 2}}>
          I've been prototyping a product I call Sleevee. I've become increasingly frustrated with how many of the cards from $10-$40 
          are shipped via paper envelopes. I've recieved far too many cards I've been excited about destroyed by basic envelopes. 
        </Typography>

        <Typography variant="h5" style={{ flexGrow: 1 }} sx={{paddingTop: 2, paddingBottom: 2}}>
          Idea behind Sleeve is to build a product that is shipped out as a flat cardstock you fold into a cardholder. Flexible enough to hold a few cards
          but also not so heavy you can't ship it via envelope
        </Typography>

        <Typography variant="h5" style={{ flexGrow: 1 }} sx={{paddingTop: 2, paddingBottom: 2}}>
          Currently Able to fit a few toploaders or a toploader with a team bag of loose cards.
        </Typography>

        <Typography variant="h5" style={{ flexGrow: 1 }} sx={{paddingTop: 2, paddingBottom: 2}}>
          Mailing Specs
        </Typography>

        <Typography variant="ul" style={{ flexGrow: 1 }}>
          <li>Must be mailed as NON-MACHINABLE</li>
          <li>Non-Machinable Stamps are ~ $1.19 currently and up to 2oz</li>
        </Typography>



        <PhotoSlideshow />
    </div>
    </Container>
  );
}

export default Header;