import React from 'react';
import config from '../config';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardMedia, Grid, Typography, Container, Button } from '@mui/material';
import useFetchSlugCard from '../hooks/useFetchSlugCard';
import EbaySoldData from './EbaySoldData';

const CardSinglePage = () => {
  const { setName, cardName } = useParams();
  const { singleCardData, error: cardError } = useFetchSlugCard(setName, cardName);

  if (cardError) return <div>Error: {cardError.message}</div>;
  if (!singleCardData || !Array.isArray(singleCardData) || singleCardData.length === 0) {
    return <div>Loading...</div>;
    }
    
    // If we reach this point, singleCardData is an array with at least one element
    const cardData = singleCardData[0];

    const baseEBAYUrl = "https://www.ebay.com/sch/i.html?_from=R40&_nkw=";
    const cardNameQuery = cardData.cardName.replace(/ /g, "+");
    const cardNumberQuery = cardData.number.replace(/\//g, "%2F"); 
    const setNameQuery = cardData.label.replace(/ /g, "+");
    const rarityQuery = cardData.rarity.replace(/ /g, "+");
    const conditionQuery = cardData.condition.replace(/ /g, "+");
    const additionalParams = "&_sacat=0&_nls=2&_dmd=2&_sop=15";
    const soldParams = "&LH_Sold=1&LH_Complete=1&_sop=13";

    // Check if cardName contains numbers
    const hasNumbers = /\d/.test(cardNameQuery);
    const query = hasNumbers ? `${cardNameQuery}+-+${setNameQuery}+${rarityQuery}+${conditionQuery}` 
                            : `${cardNameQuery}+-+${cardNumberQuery}+-+${setNameQuery}+${rarityQuery}+${conditionQuery}`;

    const ebayActiveLink = `${baseEBAYUrl}${query}${additionalParams}`;
    const ebaySoldLink = `${baseEBAYUrl}${query}${additionalParams}${soldParams}`;

    console.log("Ebay Active Listings: ", ebayActiveLink);
    console.log("Ebay Sold Listings: ", ebaySoldLink);

    const baseTCGUrl = "https://www.tcgplayer.com/product/";
    const tcgPlayerLink = `${baseTCGUrl}${cardData.productID}`
    
    console.log("TCGPlayer URL: ", tcgPlayerLink);

  return (
    <Container>
    <Grid container spacing={2} justifyContent="center" sx={{ paddingTop: 5 }}> 

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

          <Card>
            <CardMedia
              component="img"
              height="300"
              image={`${config.IMAGE_BASE_URL}${cardData.productID}.jpg`}
              alt={cardData.cardName}
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              />
            <CardContent>
              <Typography variant="h6">{cardData.cardName}</Typography>
              <Typography variant="body1">Condition: {cardData.condition}</Typography>
              <Typography variant="body1">Rarity: {cardData.rarity}</Typography>
              <Typography variant="body1">Price: ${cardData.price}</Typography>
            </CardContent>
          </Card>

      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

        <Typography variant="h6">Ebay Data:</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          href={ebayActiveLink}
          target="_blank"  // Opens the link in a new tab
          rel="noopener noreferrer"
          sx={{ margin: 1}}
        >
          ebay Active Listings 
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          href={ebaySoldLink}
          target="_blank"  // Opens the link in a new tab
          rel="noopener noreferrer"
          sx={{ margin: 1}}
        >
          eBay Sold Listings
        </Button>
        
    
        <Typography variant="h6">TCGPlayer Data:</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          href={tcgPlayerLink}
          target="_blank"  // Opens the link in a new tab
          rel="noopener noreferrer"
          sx={{ margin: 1}}
        >
          TCGPlayer Card Data
        </Button>

      </Grid>


    </Grid>
    </Container>
  );
};

export default CardSinglePage;