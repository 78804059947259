import React, { useState, useEffect } from 'react';
import { CircularProgress, CardMedia } from '@mui/material';

const ImageWithLoading = ({ src, alt, style }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return (
    <>
      {!loaded && <CircularProgress />}
      <CardMedia
        component="img"
        image={src}
        alt={alt}
        style={{ display: loaded ? 'block' : 'none', objectFit: 'contain', ...style }}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

export default ImageWithLoading;
