import React, { useEffect, useState } from 'react';
import useFetchSetData from '../hooks/useFetchSetData';
import { useParams, Link } from 'react-router-dom';
import { Card, CardContent, CardMedia, Grid, Typography, Box, Container } from '@mui/material';
import Set from './Set';
import CardDetail from './CardDetail';
import KeyList from './KeyList';



function SetDetail() {
  const {setName} = useParams();
  const { setData, error } = useFetchSetData(setName);


  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!setData) {
    return <div>Loading...</div>;
  }

  console.log("SET Data: ", setData)

  const sortedKeys = Object.keys(setData).sort((a, b) => {
    // Move keys with "Chase Singles" to the top
    if (a.includes("Chase Singles")) return -1;
    if (b.includes("Chase Singles")) return 1;
  
    // Sort by EV value (assuming it's a numeric value)
    const evA = setData[a].ev || 0;
    const evB = setData[b].ev || 0;
    return evB - evA; // descending order
  });
  
  return (
    <Container>
    <div>
      <Typography variant="h4" gutterBottom> {setData.set} </Typography>
      <Typography variant="h5" gutterBottom> Ev of this pack: {setData.totalEV} </Typography>

      {/* @TODO Keylist to jump to different sections? scroll to them at least? */}
      {/* <KeyList sortedKeys={sortedKeys} /> */}

      {sortedKeys.map((key) => {
        if (key === '_id' || key === 'set' || key === 'totalEV') return null;

        let setNameToUse;

        if (key.includes("Trainer Gallery")) {
          setNameToUse = `${setName}_Trainer_Gallery`;
        } else if (key.includes("Galarian Gallery")) {
          setNameToUse = `${setName}_Galarian_Gallery`;
        } else {
          setNameToUse = setName;
        }

        console.log("Set Name to use per key??", setNameToUse)

        return (
          <Box key={key} mb={4}>
          <div key={key}>
            <Typography variant="h5" id={key} gutterBottom sx={{ paddingBottom: '50px' }}> {key} </Typography>

            <Grid container spacing={5} sx={{ paddingLeft: '50px' }}>
              {Array.isArray(setData[key]) ? (
                setData[key].map((card, index) => (
                  <Grid item key={`${key}-${index}`} xs={12} sm={6} md={4} lg={3} >
                    <CardDetail setName={setNameToUse} cardName={card.cardName} />
                  </Grid>
                ))
              ) : (
                setData[key].cardNames.map((cardName, index) => (
                  <Grid item key={`${key}-${index}`} xs={12} sm={6} md={4} lg={3} >
                    <CardDetail setName={setNameToUse} cardName={cardName} />
                  </Grid>
                ))
              )}
            </Grid>
            
          </div>
          </Box>
        );

        
      })}
      
    </div>
    </Container>
  );
}

export default SetDetail;
