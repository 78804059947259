import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchSingleCard = (setName, cardName, condition) => {
  const [singleCardData, setSingleCardData] = useState(null);
  const [cards, setCards] = useState(null);
  const [error, setError] = useState(null);

  // Grab all cards from the set
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`/api/${setName}/cards`);
        setCards(response.data);
      } catch (error) {
        setError(error);
      }
    };
    fetchCards();
  }, [setName]);

  useEffect(() => {
    if (cards) {
      if (condition) {
        const card = cards.find((card) => card.cardName === cardName && card.condition === condition);
        if (card) {
          setSingleCardData(card);
        } else {
          setError('Card not found');
        }
      } else {
        const matchingCards = cards.filter((card) => card.cardName === cardName);
        setSingleCardData(matchingCards);
      }
    }
  }, [cards, cardName, condition]);

  return { singleCardData, error };
};

export default useFetchSingleCard;