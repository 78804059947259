import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';

const useFetchCardsData = () => {
  const [cardData, setCardData] = useState({});
  const [error, setError] = useState(null); // Add this line

  useEffect(() => {
    Object.keys(config.SETS).forEach(setName => {
      const subSets = config.SETS[setName].subSets;

      const apiCalls = subSets.map(subSet => axios.get(`/api/${subSet}/cards`));

      
      Promise.all(apiCalls)
        .then(responses => {
          const combinedData = responses.flatMap(res => res.data);
          setCardData(prevState => ({
            ...prevState,
            [config.SETS[setName].label]: combinedData,
          }));
        })
        .catch(error => {
          console.error(`Error fetching card data for ${setName}:`, error);
          setError(error);
        });
    });
  }, []);


  return { cardData };
};

export default useFetchCardsData;
