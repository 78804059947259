import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';

function Header() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }} component={Link} to="/">
          Card Eevee
        </Typography>
        <Button color="inherit" component={Link} to="/">
          Home
        </Button>
        {/* <Button color="inherit" component={Link} to="/ebay-calculator">
          eBay Calculator
        </Button> */}
        <Button color="inherit" component={Link} to="/sleevee">
          Sleevee
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;