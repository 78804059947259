const config = {
  IMAGE_BASE_URL: 'https://product-images.tcgplayer.com/fit-in/874x874/',
  SETS: {
    'Astral Radiance & Astral Radiance Trainer Gallery': {
      label: 'Astral Radiance & Astral Radiance Trainer Gallery',
      subSets: ['astral_radiance', 'astral_radiance_trainer_gallery'],
      slugCode: 'swsh10'
    },
    'Brilliant Stars & Brilliant Stars Trainer Gallery': {
      label: 'Brilliant Stars & Brilliant Stars Trainer Gallery',
      subSets: ['brilliant_stars', 'brilliant_stars_trainer_gallery'],
      slugCode: 'swsh09'
    },
    'Crown Zenith & Crown Zenith Galarian Gallery': {
      label: 'Crown Zenith & Crown Zenith Galarian Gallery',
      subSets: ['crown_zenith', 'crown_zenith_galarian_gallery'],
      slugCode: ''
    },
    'Evolving Skies': {
      label: 'Evolving Skies',
      subSets: ['evolving_skies'],
      slugCode: 'swsh07'
    },
    'Fusion Strike': {
      label: 'Fusion Strike',
      subSets: ['fusion_strike'],
      slugCode: 'swsh08'
    },
    'Lost Origin & Lost Origin Trainer Gallery': {
      label: 'Lost Origin & Lost Origin Trainer Gallery',
      subSets: ['lost_origin', 'lost_origin_trainer_gallery'],
      slugCode: 'swsh11'
    },
    'Obsidian Flames': {
      label: 'Obsidian Flames',
      subSets: ['obsidian_flames'],
      slugCode: 'sv03'
    },
    'Paldea Evolved': {
      label: 'Paldea Evolved',
      subSets: ['paldea_evolved'],
      slugCode: 'sv02'
    },
    'Paldea Fates': {
      label: 'Paldea Fates',
      subSets: ['paldean_fates'],
      slugCode: 'sv'
    },
    'Paradox Rift': {
      label: 'Paradox Rift',
      subSets: ['paradox_rift'],
      slugCode: 'sv04'
    },
    'Shrouded Fable': {
      label: 'Shrouded Fable',
      subSets: ['shrouded_fable'],
      slugCode: 'sv'
    },
    'Silver Tempest & Silver Tempest Trainer Gallery': {
      label: 'Silver Tempest & Silver Tempest Trainer Gallery',
      subSets: ['silver_tempest', 'silver_tempest_trainer_gallery'],
      slugCode: 'swsh12'
    },
    'Stellar Crown': {
      label: 'Stellar Crown',
      subSets: ['stellar_crown'],
      slugCode: 'sv'
    },
    'SV 151': {
      label: 'SV 151',
      subSets: ['sv_151'],
      slugCode: 'sv-scarlet-and-violet-151'
    },
    'SV Base Set': {
      label: 'SV Base Set',
      subSets: ['sv_base_set'],
      slugCode: 'sv01'
    },
    'Temporal Forces': {
      label: 'Temporal Forces',
      subSets: ['temporal_forces'],
      slugCode: 'sv05'
    },
    'Twilight Masquerade': {
      label: 'Twilight Masquerade',
      subSets: ['twilight_masquerade'],
      slugCode: 'sv06'
    },
  }
};

export default config;
