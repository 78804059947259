import React, { useEffect, useState } from 'react';
import config from '../config';
import { useParams, Link } from 'react-router-dom';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import useFetchSingleCard from '../hooks/useFetchSingleCard';


const CardDetail = ({ setName, cardName }) => {
  console.log("Card Detail SEt Name: ", setName)
  console.log("Card Detail Card Name: ", cardName)
  const { singleCardData, error } = useFetchSingleCard(setName, cardName);

  const formatPrice = (price) => price !== undefined ? price.toFixed(2) : 'N/A';

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!singleCardData) {
    return <div>Loading...</div>;
  }

  if (Array.isArray(singleCardData)) {
    // Render multiple cards
    return (
      <Grid container spacing={3}>
        {singleCardData.map((card) => (
            <Link to={`/card/${setName}/${card.slug}`} style={{ textDecoration: 'none' }}>

              <Card style={{ width: '100%', maxWidth: 300, margin: 'auto' }}>
                <CardMedia
                  component="img"
                  height="300"
                  image={`${config.IMAGE_BASE_URL}${card.productID}.jpg`}
                  alt={card.cardName}
                  style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                  />
                <CardContent>
                  <Typography variant="h6">{card.cardName} ({card.condition})</Typography>
                  <Typography variant="body2">Price: ${formatPrice(card.price)}</Typography>
                </CardContent>
              </Card>

              </Link>
        ))}
      </Grid>
    );
  } else { 
    // Render single card
    return (
      <Link to={`/card/${setName}/${singleCardData.slug}`} style={{ textDecoration: 'none' }}>

        <Card style={{ width: '100%', maxWidth: 300, margin: 'auto' }}>
          <CardMedia
            component="img"
            height="300"
            image={`${config.IMAGE_BASE_URL}${singleCardData.productID}.jpg`}
            alt={cardName}
            style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            />
          <CardContent>
            <Typography variant="h6">{cardName}</Typography>
            <Typography variant="body2">Price: ${formatPrice(singleCardData.price)}</Typography>
          </CardContent>
        </Card>

      </Link>
    );
  }
}

export default CardDetail;


