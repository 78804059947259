import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchSlugCard = (setName, slug) => {
  const [singleCardData, setSingleCardData] = useState(null);
  const [cards, setCards] = useState(null);
  const [error, setError] = useState(null);

  // Grab all cards from the set
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`/api/${setName}/cards`);
        const cards = response.data;
        const matchingCards = cards.filter((card) => card.slug === slug);
        setSingleCardData(matchingCards);
      } catch (error) {
        setError(error);
      }
    };
    fetchCards();
  }, [setName, slug]);

  return { singleCardData, error };
};

export default useFetchSlugCard;