import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';

const useFetchSetData = (set) => {
    const [setData, setSetData] = useState({});
    const [error, setError] = useState(null); // Add this line

    useEffect(() => {
        axios.get(`/api/${set}`)
            .then(response => {
            console.log('Set Data from EV fetched:', response.data);
            setSetData(response.data[0]); // Assuming the response data is an array with one object
            })
            .catch(error => {
            console.error(`Error fetching set data for ${set.set}:`, error);
            setError(error);
            });
        
    }, [set]);

    return {setData};
};

export default useFetchSetData;